'use strict';

angular.module('FormManagement').controller('FormManagementIndexCtrl', ['$scope', '$rootScope', '$state', '$usersContextService', '$customerFormService', '$formVersionUtils', '$uibModal', '$sidePanel', '$modalService', '$integrationEolUtils', '$window', '$location', 'moreConstants', function ($scope, $rootScope, $state, $usersContextService, $customerFormService, $formVersionUtils, $uibModal, $sidePanel, $modalService, $integrationEolUtils, $window, $location, moreConstants) {
  const self = this;
  $scope.temp = {};
  $scope.sharedFormTemplate = null;
  $scope.integrationsEol = false;
  $scope.edit = edit;
  $scope.showHistory = showHistory;
  $scope.showPermissions = showPermissions;

  self.loading = true;
  self.updateName = updateName;
  self.updateDescription = updateDescription;
  self.selectIcon = selectIcon;
  const ignore = ['meta', 'id'];
  const jsonDiff = jsonDiffPatch.create({
    propertyFilter: function(name) {
      return !ignore.includes(name) && name.slice(0, 1) !== '$';
    }
  });

  init();

  ////////
  function init () {
    setupMenuItems();

    $scope.$on('form.added', function (event, data) {
      $scope.formVersions = [];
      edit(data.form, data.formVersion ? data.formVersion : $formVersionUtils.newFormVersion(data.form));
    });

    $scope.$on('form.changed', (event, data) => {
      $location.search({formId: data.form.id});
      setupMenuItems(data.folder.id, data.form.id);
      $scope.form = data.form;
      $scope.integrationsEol = false;
      self.loading = true;

      if ($scope.form.publishedVersion) {
        loadForm($scope.form.publishedVersion.formVersion);
      } else {
        loadForm();
      }
    });

    $rootScope.$on('form.removed', function () {
      $scope.form = null;
      $scope.formVersion = null;
    });

    enableBetaFormBuilder();
  }

  function loadForm(formVersionId) {
    if (formVersionId) {
      $customerFormService.getFormVersion($state.params.customerId, $scope.form.id, formVersionId).$promise.then(formVersion => {
        if (formVersion.id !== $scope.form.publishedVersion.formVersion) {
          $scope.form.publishedVersion.formVersion = formVersion.id;
          $scope.form.publishedVersion.publishedBy = formVersion.meta.lastUpdatedBy;
          $scope.form.publishedVersion.publishedDate = formVersion.meta.lastUpdated;
        }
        $scope.formVersion = formVersion;
        $scope.integrationsEol = $integrationEolUtils.hasEolIntegrations(formVersion);
        return $customerFormService.getFormVersions($state.params.customerId, $scope.form.id).$promise;
      }).then(formVersions => {
        $scope.formVersions = formVersions;
        $scope.changesAvailable = jsonDiff.diff($scope.formVersion, formVersions[0]);
      }).finally(() => {
        self.loading = false;
      });
    } else {
      $customerFormService.getFormVersions($state.params.customerId, $scope.form.id).$promise.then((formVersions) => {
        $scope.formVersions = formVersions;
        $scope.formVersion = getFormVersionToDisplay(formVersions);
        $scope.integrationsEol = $integrationEolUtils.hasEolIntegrations($scope.formVersion);
      }).finally(() => {
        self.loading = false;
      });
    }
  }

  function updateName (name) {
    $customerFormService.updateName($state.params.customerId, $scope.form.id, name).$promise.then(form => {
      $rootScope.$broadcast('form.updated', { form: form });
    });
  }

  function updateDescription (description) {
    $customerFormService.updateDescription($state.params.customerId, $scope.form.id, description);
  }

  function selectIcon() {
    $uibModal.open({
      template: require('../../../modules/IconPicker/templates/icon-picker.modal.html'),
      controller: 'IconPickerModalCtrl',
      controllerAs: 'ctrl'
    }).result.then(function (icon) {
      $customerFormService.updateIcon($state.params.customerId, $scope.form.id, icon).$promise.then(form => {
        $scope.form = form;
        $rootScope.$broadcast('form.updated', { form: form });
      });
    });
  }

  function setupMenuItems (folderId, formId) {
    const overViewMenuItem = {
      name : 'VIEW_MANAGEMENT_OVERVIEW',
      state : 'overview'
    };
    const registrationsMenuItem = {
      name : 'VIEW_MANAGEMENT_REGISTRATIONS',
      state : 'registrations'
    };
    const tasksMenuItem = {
      name : 'VIEW_MANAGEMENT_TASKS',
      state : 'tasks'
    };

    $scope.menuItems = {
      'form' : [overViewMenuItem]
    };

    $scope.simpleMenuItems = {
      'form' : [overViewMenuItem]
    };

    if ($usersContextService.canViewSubmissions(formId)) {
      $scope.menuItems.form.push(registrationsMenuItem);
      $scope.simpleMenuItems.form.push(registrationsMenuItem);
    }
    if ($usersContextService.canViewTasks(formId)) {
      $scope.menuItems.form.push(tasksMenuItem);
    }

    $scope.canManageForm = $usersContextService.canManageForm(formId);
    $scope.canArchiveForm = $usersContextService.canArchiveForm(formId);
    $scope.canCopyForm = $usersContextService.canCopyForm(formId);
    $scope.canMoveForm = $usersContextService.canMoveForm(folderId, formId);

    if ($state.current.name.indexOf(registrationsMenuItem.state) !== -1 && !$usersContextService.canViewSubmissions(formId) ||
        $state.current.name.indexOf(tasksMenuItem.state) !== -1 && !$usersContextService.canViewTasks(formId)) {
      $state.go('^.' + overViewMenuItem.state);
    }
  }

  function getFormVersionToDisplay (formVersions) {
    if (!formVersions || formVersions.length === 0) {
      // show empty form
      return $formVersionUtils.newFormVersion($scope.form);
    }
    return formVersions[formVersions.length - 1];
  }

  function edit (form, formVersion, beta) {
    const go = beta ? goBetaFormBuilder: goFormBuilder;
    if (formVersion && formVersion.meta.status === 'DRAFT') {
      // Only draft available, otherwise it would be FINAL here
      go(formVersion);
    } else {
      const draftVersions = $scope.formVersions.filter(version => {
        return version.meta.status === 'DRAFT';
      });
      if (draftVersions && draftVersions.length > 0) {
        //Should be one for now, retrieve latest just in case. Newest versions appear first in list.
        go(draftVersions[0]);
      } else {
        $customerFormService.createFormVersion($state.params.customerId, form.id, formVersion).$promise.then(version => {
          go(version);
        }, (err) => {
          if (err && err.status === 409) {
            // FormVersions are outdated, because new DRAFT was available
            $customerFormService.getFormVersions($state.params.customerId, $scope.form.id).$promise.then((formVersions) => {
              $scope.formVersions = formVersions;
              $scope.formVersion = getFormVersionToDisplay(formVersions);
            });
            $modalService.errorModal('FORM_VERSION_DRAFT_ALREADY_AVAILABLE');
          } else {
            $modalService.errorModal();
          }
        });
      }
    }
  }

  function goFormBuilder (formVersion) {
    const newParams = angular.copy($scope.$state.params);
    newParams.formId = formVersion.formId;
    newParams.formVersionId = formVersion.id;
    $scope.$state.go('portal.customers.form.editor.edit', newParams);
  }

  function goBetaFormBuilder(formVersion) {
    const userName = encodeURIComponent($scope.currentUser.consumerKey);
    $window.open(`${moreConstants.betaPlatformEndpoint}/customers/${$state.params.customerId}/forms/${formVersion.formId}/formVersions/${formVersion.id}?username=${userName}`);
  }

  function enableBetaFormBuilder () {
    window.posthog.onFeatureFlags(() => {
      $scope.hasBetaFormEditorFeature = window.posthog.isFeatureEnabled('beta-form-editor');

      if ($scope.hasBetaFormEditorFeature && !self.messageListener) {
        self.messageListener = function (event) {
          if (event.data.type === 'formVersionSaved') {
            loadForm($scope.form.publishedVersion.formVersion);
          }

          if (event.data.type === 'formVersionPublished') {
            loadForm(event.data.formVersionId);
          }
        };

        window.addEventListener('message', self.messageListener);

        $scope.$on('$destroy', function () {
          window.removeEventListener('message', self.messageListener);
        });
      }
    });
  }

  function showPermissions () {
    $sidePanel.open({
      template : require('../templates/formmanagement.permissions.html'),
      resolve : {
        form : () => $scope.form,
        currentCustomer : () => $scope.customer
      },
      controller : 'FormManagementPermissionsCtrl as ctrl'
    });
  }

  function showHistory () {
    $uibModal.open({
      template : require('../templates/formmanagement.history.html'),
      controller: 'FormManagementHistoryCtrl as ctrl',
      size: 'lg',
      windowClass: 'modal-fullscreen',
      resolve: {
        formVersions: () => $scope.formVersions,
        selectedVersion : () => $scope.formVersion,
        form: () => $scope.form,
      }
    }).result.then((result) => {
      if (result.form && result.formVersion) {
        $scope.formVersion = result.formVersion;
        $scope.form = result.form;
      }
      if (result.formVersions) {
        $scope.formVersions = result.formVersions;
      }
    });
  }

}]);
